<template>
  <div class="breadcrumb csn-breadcrumb csn-rb">
    <div class="breadcrumb-box" v-if="selectedItem.name">
      <div class="category">{{ t('rewards') }}</div>
      <div class="page-title">
        <ol class="breadcrumb-title">
          <li class="breadcrumb-item">
            <span>
              {{ selectedItem.name }}
            </span>
          </li>
        </ol>
      </div>
    </div>
    <div class="game-filters-box">
      <div class="row csn-game-short-button">
        <ul class="filters csn-breadcrumb-filters">
          <li
            v-for="(item, index) in list"
            :key="index"
            class="csn-rb-item"
            :class="{
              'csn-rb-item-selected': item.id === selectedItem.id,
            }"
            @click="selectItem(item)"
          >
            <Route
              to="#"
              :class="{
                'csn-breadcrumb-selected': selectedItem === item,
              }"
            >
              <!-- <component :is="breadcrumbIconDictionary[item]"></component> -->
              <img :src="item.imageLink" alt="" class="casino-icon" />
              <div>{{ item.name }}</div>
            </Route>
          </li>
        </ul>
      </div>
    </div>
    <div class="csn-rb-coin-box">
      <div class="csn-rb-coin-title">{{ t('platin_coins') }}</div>
      <div class="csn-rb-coin-container">
        <span class="csn-rb-coin-value">218</span>
        <span
          class="casino-icon casino-icon-platin-club csn-rb-coin-image"
        ></span>
      </div>
    </div>
  </div>
</template>
<script>
import { REWARD_BREADCRUMBS, EMPTY_OBJECT } from '@/constants'
import { head } from '@/helpers'

export default {
  name: REWARD_BREADCRUMBS,
  components: {
    Route: () => import('@/components/Route'),
  },
  data: () => ({
    selectedItem: EMPTY_OBJECT,
  }),
  props: {
    list: Array,
  },
  computed: {
    t() {
      return this.$createComponentTranslator(REWARD_BREADCRUMBS)
    },
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item
      this.$emit('select-category', item)
    },
  },
  watch: {
    list: {
      immediate: true,
      handler(list) {
        this.selectItem(head(list))
      },
    },
  },
}
</script>
